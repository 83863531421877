export default {
  lang: 'English',
  test: 'test',
  AddToCart: 'Add To Cart',
  changeLangSuccess: 'Language switch successful !',
  changeCurSuccess: 'Currency switch successful !',
  Input: {
    email: 'Please enter the correct email',
    number: 'Please enter a number',
    input: 'Please input ',
    text: '',
    date: 'Please enter a date',
    password: 'Please input passwords',
    logopassword: 'Please input passwords',
    confirmpassword: 'Two inconsistent input passwords',
    textarea: '',
    nomessage: 'No Message',
    phoneincorrect: 'The phone number format is incorrect',
    pleaseenterphone: 'Please input phone'
  },
  Message: {
    Logout: 'Timeout ! login now ?',
    Message: 'Message',
    Confirm: 'Ok',
    Cancel: 'Close',
    SucceedInOperating: 'Succeed in Operating',
    SavedSuccessfully: 'Saved successfully',
    MemberInfo: 'Your personal information is not perfect, which may affect your access to the offer',
    msg401: 'Insufficient authority!',
    msg400: 'System abnormal!',
    msg404: 'Request interface does not exist!',
    msg500: 'Server error!',
    msg502: 'Network error!',
    msg503: 'The service is not available, the server is temporarily overloaded or maintained.',
    ViewDetail: 'VIEW DETAILS',
    AdditionalDiscount: 'The Additional Discount is In Effect',
    Delete: 'Delete',
    SuccessfullyDeleted: 'Successfully Deleted',
    FollowUs: 'Follow Us',
    Evidence: 'Evidence',
    UploadEvidence: 'Upload Evidence',
    OrderNumber: 'Order Number',
    Remark: 'Remark',
    Image: 'Image',
    SubmitSuccess: 'Submitted Successfully',
    SubmitFailed: 'Submit Failed',
    DeliveryDate: 'Delivery Date',
    DeliveryTime: 'Delivery Time',
    DeliveryInstructions: 'Delivery Instructions',
    DeliveryMark: 'Please make a reservation one day in advance. The order is cut off at 10 PM every day. The Package over 800 packages, urban delivery.Only Payment complete before 10PM can be delivered the next day',
    All: 'All',
    PlsAddPiUpAddr: 'Please add relevant self-referral address information ! ',
    NoneAddPiUpAddr: 'Unable to query the relevant address information!',
    Save: 'Save',
    SaveAll: 'SaveAll',
    noRMBStripe: 'Currency RMB does not support Stripe payment, please choose another payment method or switch currency mode',
    minLengthError: 'The character length cannot be less than',
    Pleaseaddress: 'Please input Address'
  },
  Account: {
    MemberInformation: 'Profile',
    MyMessages: 'Messages',
    MyFavorite: 'Favorite',
    MyOrder: 'Order',
    DeliveryAddress: 'Address',
    Logout: 'Logout',
    Welcome: 'WELCOME!',
    SISA: 'Succeed in Choosing',
    MemberCentral: 'MemberCentral'
  },
  product: {
    ProductCode: 'Product Code',
    buy: 'buy',
    addToCart: 'Add To Cart',
    favorite: 'Favorite',
    priceTitle: 'price',
    countTitle: 'Quantity',
    youWouldLike: 'some products you would like',
    moreTips: 'Click to load more',
    none: 'None',
    noneInCat: 'no prodcut belong to this catalog',
    noneInSearch: 'not found',
    notifyMe: 'notifyMe',
    purchasing: 'purchasing',
    comments: { title: 'Comments', none: 'none' },
    detail: 'detail',
    overView: 'overView',
    specification: 'Specification',
    attr: { 0: 'Size', 1: 'Color' },
    SaleOut: 'Sold Out',
    offSale: 'offSale',
    Recommend: 'Recommend',
    Remarks: 'Remarks',
    EnterRemaks: 'please enter remarks',
    up: 'Last',
    down: 'Next',
    logouted: 'you are logouted!',
    loginow: 'login now',
    successInAdding: 'succeed in adding',
    successInRemoving: 'succeed in removing',
    replyFromShopkeeper: 'Reply from shopkeeper',
    per: 'page',
    page: '',
    buyNow: 'buy Now'
  },
  pyramidSelling: {
    ps: 'pyramid selling'
  },
  Shoppingcart: {
    ShoppingcartTitle: 'Shoppingcart',
    Merchant: 'Merchant',
    Product: 'Product',
    Quantity: 'Quantity',
    Price: 'Price',
    Total: 'Total',
    CheckoutList: 'Checkout',
    Checkout: 'checkout',
    goTOCart: 'ToShoppingCart',
    vat: 'Include VAT',
    None: 'No article in your shopping cart!Shopping now.',
    more: 'more',
    hide: 'hide'
  },
  Login: {
    doLogin: 'Login',
    LoginTitle: 'Login',
    NewUser: 'New buyer',
    RegNow: 'Register now',
    ForgetPwd: 'Forget Password?',
    RememberMe: 'Remember Me',
    FaceBookUse: 'Login as Facebook',
    UserName: 'Login Name/Email',
    EnterEmail: 'Please Enter The Email',
    CorrectEmail: 'Please Enter The Correct Email',
    LoginPwd: 'Please enter your password',
    LoginoldPwd: 'Incorrect password',
    Loginerror: 'Incorrect account or password',
    Needtologinagain: 'You need to re-login?'
  },
  Register: {
    ResetPwd: 'Reset password',
    or: 'or',
    RegisterTitle: 'Registration',
    UserEmail: 'Email',
    UserRegPassword: 'Password',
    UserNewPassword: 'New Password',
    UserOldPassword: 'Old Password',
    UserRegTips: 'Min.6 characters with letters and numbers',
    UserConfirmPassword: 'Confirm',
    UserFirstName: 'First Name',
    UserLastName: 'Last Name',
    UserContactNumber: 'Phone',
    UserBirthDate: 'Birth Date',
    UserGender: 'Gender',
    UserMale: 'Male',
    UserFemale: 'Female',
    UserLanguage: 'Language',
    UserLanguageE: 'English',
    UserLanguageT: 'Traditional Chinese',
    UserLanguageS: 'Simplified Chinese',
    RegisterBtn: 'Register',
    RegisterAgree: 'I agree to the terms of purchase and terms of use',
    RegisterEmail: 'Email cannot be empty',
    RegisterEmail01: 'Please enter a formal mailbox format',
    RegisterPassword: 'Please enter your password',
    RegisterNewPassword: 'Please enter your password again',
    RegisterNewPassword01: 'Two inconsistent input passwords',
    RegisterFirstName: 'Please enter your name',
    RegisterLastName: 'Name please enter your name',
    RegisterAgree_check: 'Please check',
    RegisterMobile: 'Please enter your phone',
    Succeed: 'Registration success',
    Succeed1: 'Success',
    Login: 'Login now!',
    Registerdigital: 'Length from 8 to 20 must contain uppercase letters, lowercase letters and numbers',
    promotionalinformation: 'I agree to receive promotional information'
  },
  DeliveryAddress: {
    AddAddress: 'Add address',
    ChooseDeliveryAddress: 'Select delivery address',
    EditBtn: 'Edit',
    DeleteBtn: 'Delete',
    AddDeliveryAddress: 'New Address',
    PostalCode: 'PostalCode',
    Area: 'Area',
    Province: 'Province',
    District: 'District',
    Address: 'Address',
    DefaultAddress: 'Set as default delivery address?',
    SaveBtn: 'Save',
    ResetBtn: 'Reset',
    CloseBtn: 'Close',
    YesBtn: 'Yes',
    NoBtn: 'No',
    AddFirstName: 'First Name',
    AddLastName: 'Last Name',
    UserContactNumber: 'Phone',
    Mobile: 'Mobile',
    Addressprovince: 'Please choose the province',
    AddressArea: 'Please choose the area',
    Detail: 'detail',
    Allsave: 'All save'
  },
  Action: {
    ViewMore: 'View More',
    SignUp: 'Sign Up',
    ReturnHomePage: 'Return HomePage',
    OrderDetails: 'Order Details',
    Save: 'Save',
    Checkout: 'Checkout',
    Confirm: 'Confirm'
  },
  MyCoupon: {
    MyCoupon: 'MyCoupon',
    Coupon: 'Coupon',
    PeriodOfValidity: 'Time',
    NoCoupon: 'NoCoupon',
    CouponStatus: 'Coupon Status',
    SelectCouponStatus: 'Please Slect Coupon Status',
    TotalNum: 'Total',
    Piece: 'Piece',
    NotUse: 'Invalid',
    Used: 'Valid',
    Among: 'Among',
    DoNotUse: 'Invalid',
    All: 'All'
  },
  MyFavorite: {
    MyFavorite: 'MyFavorite',
    LearnMore: 'Learn More',
    RemoveProduct: 'Remove',
    RemoveSuccess: 'Remove Successfully',
    noFavorite: 'Come take my money and buy my baby!!'
  },
  Notification: {
    MyMessages: 'My Messages',
    MsgList: 'Message List',
    TotalRecQtyWithSymbol: 'Total ：',
    UnreadWithSymbol: 'Unread ：',
    MsgUnreadOnly: 'Unread Message Only',
    MsgMarkAsRead: 'Mark as read',
    CheckAllOrNot: 'Check All/Not',
    MsgSender: 'Sender',
    MsgSubject: 'Subject',
    MsgSenderDate: 'Send Date',
    MsgReadStatus: 'Status',
    Details: 'Details',
    More: 'More',
    Back: 'Back',
    MsgContent: 'Content',
    Send: 'Send',
    UnRead: 'UnRead',
    Aread: 'Already read'
  },
  Forgetpassword: {
    ForgetPassword: 'ForgetPassword',
    ResetPwdTips:
      'Please enter the registered email you used for registration, then click Next to continue',
    LoginNameEmail: 'Login Name/Email',
    NextStep: 'Next',
    ResetPwdSuccess: 'Temp Password is sended to your email,please check.',
    NotEmail: 'Cannot receive the Activate Email',
    ToReEnter: 'Click here to re-enter',
    EnterEmail: 'Please Enter The Email',
    CorrectEmail: 'Please Enter The Correct Email',
    ResetPwd: 'Reset password'
  },
  MemberInfo: {
    MemberInfoTitle: 'MyProfile',
    UserAccount: 'UserAccount',
    ModifyPassword: 'Password',
    EnterUserName: 'Please Enter FristName',
    EnterUserLastName: 'Please Enter LastName',
    EnterUserPhone: 'Please Enter Phone',
    EnterUserBrithDate: 'Please Select BrithDate',
    EnterOldPwd: 'Please Enter Old Password',
    EnterNewPwd: 'Please Enter New Password',
    EnterNumber: 'required number',
    EnterComfirmPwd: 'Please Enter Comfirm Password',
    PwdNotCorrect: 'The passwords not match twice',
    DDMM: 'DD/MM'
  },
  Order: {
    Paid: 'Paid',
    Fail: 'Pay Fail',
    OrderTitle: 'My Order',
    OrderDetail: 'OrderDetail',
    OrderPay: 'Pay Now',
    OrderCancel: 'OrderCancel',
    OrderComplete: 'OrderComplete',
    OrderClose: 'OrderClose',
    CartSubtotal: 'Cart Subtotal',
    DeliveryCharge: 'Delivery Charge',
    TotalPrice: 'TotalPrice',
    OrderStatus: 'Order Status',
    OrderDateTime: 'Order Date & Time',
    OrderNumber: 'Order Number',
    OrderSearch: 'Order Search',
    EnterProductName: 'Please Enter Product Name',
    Items: 'ITEMS',
    OrderComment: 'Comment',
    PromotionCode: 'Promotion Code',
    RecommendationCode: 'Recommendation Code',
    CommodityPrice: 'Commodity Price',
    Full: 'Full',
    Minus: 'Minus',
    Hit: 'Hit',
    Precent: 'Precent off',
    Discount: 'Discount',
    Cancel: 'cancel'
  },
  Payment: {
    OrderDetails: 'Order Details',
    ReturnHomePage: 'Return HomePage',
    PayStatus: 'PayStatus',
    PaymentFailTips:
      'The payment was fail, pleasse try again after checking of your account.',
    PaymentSuccessTips: 'The payment was successful.'
  },
  CheckOut: {
    Ordersummary: 'Order Summary',
    Input: 'Input',
    expiryDate: 'Expiry Date',
    awsl: 'awsl',
    expressError: 'Express outage!Please choose others.',
    pay: 'pay',
    CheckOutTitle: 'Check Out',
    Merchant: 'Merchant',
    Product: 'Product',
    Qty: 'Qty',
    Price: 'Price',
    SplitDelivery: 'Split Delivery',
    ShippingMethod: 'Shipping Method',
    PleaseSelect: 'Please select',
    Selected: 'Selected',
    Name: 'Name',
    Phone: 'Phone',
    Address: 'Address',
    Pickup: 'Pick up',
    Change: 'Change',
    DeliveryCharge: 'Delivery Charge',
    SubTotal: 'Sub-Total',
    PayBy: 'Pay By',
    Couponcode: 'Promotion Code',
    confirm: 'Confirm',
    Total: 'Total',
    BacktoShoppingCart: 'Back to Shopping Cart',
    Last: 'Last',
    Checkout: 'Check out',
    Next: 'Next',
    Delivery: 'Delivery',
    SelectDeliveryAddress: 'Select delivery address',
    LastName: 'Last Name',
    Edit: 'Edit',
    Delete: 'Delete',
    Select: 'Select',
    NewAddress: 'New Address',
    PickUp: 'Pick Up',
    FirstName: 'First Name',
    ZipCode: 'Zip Code',
    Mobile: 'Mobile',
    Country: 'Area',
    Province: 'Region / Province',
    City: 'District',
    Close: 'Close',
    PickupDate: 'Pick up Date',
    PickupTime: 'Pick up Time',
    Morning: 'Morning',
    Afternoon: 'Afternoon',
    PickAddressError: 'Incomplete PickAddress information',
    AddressLock: 'This Address has been Selected',
    CompanyName: 'CompanyName',
    CompanyPhone: 'CompanyPhone',
    CompanyAddress: 'CompanyAddress',
    PickupAddress: 'Pick up Address',
    Save: 'Save',
    PleaseChoose: 'Please Choose',
    PleaseSelectdate: 'Please Select date',
    PleaseSelecttime: 'Please select time',
    ConfirmationOfOrder: 'Confirmation Of Order',
    Concessions: 'Concessions',
    PaymentMethod: 'PaymentMethod',
    Complete: 'Complete',
    OrderPreview: 'Order Preview',
    CandP: 'Cant use coupon and promotionCode at the same time.',
    promotionCodeError: 'Please enter the correct promotionCode',
    promotionCodetips1: 'Please delete the existing promotion code first',
    inputPromotionCode: '',
    Use: 'Use',
    As: 'As',
    confirmAddress: 'Please confirm the delivery address first',
    PaymentMethodError: 'The payment method has not been selected yet!',
    OrderSent: 'Order has been sent',
    PaymentProcess: 'Please complete the payment process',
    CountryName: 'Country Name',
    ProvinceName: 'Province Name',
    CityName: 'City Name',
    SFPointType: 'Self Point Type'
  },
  Concessions: {
    Cannot: 'Offer cannot be used at the same time'
  },
  Address: {
    Country: 'Please choose country',
    Province: 'Please choose province',
    Address: 'Please enter detail address'
  },
  MemberPoints: {
    MemberPoints: 'MemberPoints',
    PointsDetail: 'Points Detail',
    PointsIncome: 'Points Income',
    PointsSpending: 'Points Spending',
    OrderTime: 'Order Time',
    OrderNo: 'Order Number',
    Income: 'Income',
    Spending: 'Expenditure',
    IncomeSpending: 'Income/Spending',
    DetailedDescription: 'Detailed Description',
    TotalPoints: 'Total Integral',
    Hello: 'Hello',
    Equals: 'Equals',
    Integral: 'Integral',
    PointsTips: 'Frequently asked questions about integral use',
    PointsType: 'Points Type',
    Points: 'Points',
    PointsMode: 'Points Mode'
  },
  Cms: {
    AboutUS: 'About US',
    PlsChoose: 'Please click to select the content you want to view :'
  },
  promotion: {
    enter: 'enter',
    main: 'Selected textbooks for gifted children',
    monterssori: {
      title1: 'Montessori teaching method',
      title2: 'Develop the habit of independent learning and exploration',
      banner1: 'Teach Yourself',
      banner2: 'Initiative learning',
      banner3: 'build personality'
    }
  },
  RegNPay: {
    BackText: 'Back',
    UploadButtonText: 'Upload files(jpg, png, pdf)',
    UploadingText: 'Uploading...',
    ConfirmText: 'OK',
    UploadSuccessfulText: 'Upload Successful',
    UploadFailText: 'Upload failed',
    NoFileText: 'No file selected',
    UploadLengthText: 'You can only upload {0} files at most',
    UploadSizeText: 'You can only upload {0} M at most',
    PleaseSelect: 'Please Select',
    SubRecord: 'Submission Record',
    ReferenceNum: 'Reference Number',
    SubmissionDate: 'Submission Date',
    Print: 'Print',
    PlsPayWay: 'Please select the mode of payment',
    PreviewTitleText: 'Please confirm your information again.',
    FormatErrorText: 'Please input correct email address.',
    RequiredText: 'Required'
  },
  home: {
    News: 'News',
    More: 'More',
    Category: 'Category',
    Weaccept: 'We Accept',
    Order: 'Order',
    TelSearch: 'Tel',
    AllProduct: 'All Product',
    ResetProduct: 'Reset',
    Address: 'Address',
    ViewDetail: 'Detail',
    Screening: 'Screening',
    Resetall: 'Reset all filters',
    PriceHL: 'Price from high to low',
    PriceLH: 'Price from low  to high',
    Thatsall: 'That is all'
  }
};
