export default {
  lang: '繁',
  test: '测试',
  AddToCart: '加入購物車',
  changeLangSuccess: '語言切換成功！',
  changeCurSuccess: '貨幣切換成功！',
  Input: {
    email: '請輸入正確的電郵',
    number: '請輸入數字',
    input: '請輸入',
    text: '',
    date: '請選擇日期',
    password: '請輸入密碼',
    logopassword: '請輸入密碼',
    confirmpassword: '兩次輸入密碼不一致',
    textarea: '',
    nomessage: '暫無數據',
    phoneincorrect: '電話號碼格式不正確',
    pleaseenterphone: '請輸入電話號碼'
  },
  Message: {
    Logout: '您尚未登陸或已登入超時，請重新登入',
    Message: '消息',
    Confirm: '確認',
    Cancel: '關閉',
    SucceedInOperating: '操作成功',
    SavedSuccessfully: '保存成功',
    MemberInfo: '您的個人信息尚未完善，這有可能會影響您獲取優惠',
    msg401: '權限不足！',
    msg400: '系統異常！',
    msg404: '請求接口不存在！',
    msg500: '服務器錯誤！',
    msg502: '网络错误！',
    msg503: '服務不可用，服務器暫時過載或維護。',
    ViewDetail: '瞭解更多',
    AdditionalDiscount: '額外折扣已生效',
    Delete: '刪除',
    SuccessfullyDeleted: '刪除成功',
    FollowUs: '關注我們',
    Evidence: '憑據',
    UploadEvidence: '上傳憑據',
    OrderNumber: '訂單編號',
    Remark: '備註',
    Image: '圖片',
    SubmitSuccess: '提交成功',
    SubmitFailed: '提交失敗',
    DeliveryDate: '送貨日期',
    DeliveryTime: '送貨時段',
    DeliveryInstructions: '送貨指示',
    DeliveryMark: '預訂請預早⼀天，每天晚上10 點截單，套餐滿800 包市區送貨。10點前⼊數⽅可隔天送貨。',
    All: '全部',
    PlsAddPiUpAddr: '請添加相關自提地址信息！',
    NoneAddPiUpAddr: '查詢不到相關自提地址信息！',
    Save: '保存',
    SaveAll: '保存所有',
    noRMBStripe: '貨幣RMB不支持Stripe支付，請選擇其他支付方式或切換貨幣模式',
    minLengthError: '字符長度不能小於',
    Pleaseaddress: '請輸入地址'
  },
  Account: {
    MemberInformation: '用戶信息',
    MyMessages: '我的信箱',
    MyFavorite: '我的最愛',
    MyOrder: '我的訂單',
    DeliveryAddress: '送貨地址',
    Logout: '登出',
    Welcome: '歡迎!',
    SISA: '選擇成功',
    MemberCentral: '會員中心'
  },
  product: {
    ProductCode: '產品編號',
    buy: '立即購買',
    addToCart: '加入購物車',
    favorite: '加入我的清單',
    priceTitle: '價格',
    countTitle: '數量',
    youWouldLike: '你可能喜歡',
    moreTips: '點擊加載更多',
    none: '沒有了',
    noneInCat: '當前目錄無產品，請選擇其他目錄',
    noneInSearch: '搜索不到產品',
    notifyMe: '到貨通知',
    purchasing: '采購中',
    comments: { title: '商品評論', none: '該商品暫無評論' },
    detail: '產品詳情',
    overView: '產品簡介',
    specification: '使用説明',
    attr: { 0: '尺寸', 1: '顔色' },
    SaleOut: '售罄',
    offSale: '已下架',
    Recommend: '會員推薦',
    Remarks: '備注',
    EnterRemaks: '請填寫備注',
    up: '上一個',
    down: '下一個',
    logouted: '您尚未登錄！',
    loginow: '現在重新登入？',
    successInAdding: '添加成功',
    successInRemoving: '取消成功',
    replyFromShopkeeper: '店主回復',
    per: '第',
    page: '頁',
    buyNow: '立即購買'
  },
  pyramidSelling: {
    ps: '传销'
  },
  Shoppingcart: {
    ShoppingcartTitle: '購物車',
    Merchant: '商家',
    Product: '商品',
    Quantity: '數量',
    Price: '價錢',
    Total: '共',
    CheckoutList: '確認訂單',
    Checkout: '安全結算',
    goTOCart: '前往購物車',
    vat: '包含增值稅',
    None: '購物車裏空空如也，現在去購買？',
    more: '更多',
    hide: '收起'
  },
  Login: {
    doLogin: '登入',
    LoginTitle: '會員登入',
    NewUser: '新用戶',
    RegNow: '現在注冊',
    ForgetPwd: '忘記密碼?',
    RememberMe: '記住我',
    FaceBookUse: '使用facebook賬戶登錄',
    UserName: '登錄用戶名/電郵地址',
    EnterEmail: '請輸入電郵地址',
    CorrectEmail: '請輸入正確電郵地址',
    LoginPwd: '請輸入密碼',
    LoginoldPwd: '密碼不正確',
    Loginerror: '賬號或密碼不正確',
    Needtologinagain: '需要重新登入？'
  },
  Register: {
    ResetPwd: '重設密碼',
    or: '或',
    RegisterTitle: '註冊會員',
    UserEmail: '電郵',
    UserRegPassword: '密碼',
    UserNewPassword: '新密碼',
    UserOldPassword: '舊密碼',
    UserRegTips: '最少6個由字母或數字組成的字符',
    UserConfirmPassword: '確認密碼',
    UserFirstName: '名字',
    UserLastName: '姓氏',
    UserContactNumber: '聯繫電話',
    UserBirthDate: '出生日期',
    UserGender: '性別',
    UserMale: '男',
    UserFemale: '女',
    UserLanguage: '語言',
    UserLanguageE: '英文',
    UserLanguageT: '繁體',
    UserLanguageS: '简体',
    RegisterBtn: '注冊',
    RegisterAgree: '我同意購買條款和使用條款',
    RegisterEmail: '電郵不能為空',
    RegisterEmail01: '請輸入正式的電郵格式',
    RegisterPassword: '請輸入密碼',
    RegisterNewPassword: '請再次輸入密碼',
    RegisterNewPassword01: '兩次輸入密碼不一致',
    RegisterFirstName: '請輸入您的姓名',
    RegisterLastName: '請輸入您的名稱',
    RegisterAgree_check: '請勾選',
    RegisterMobile: '請輸入您的手提電話',
    Succeed: '注冊成功',
    Succeed1: '恭喜你注冊成功',
    Login: '跳轉登錄',
    Registerdigital: '長度由8至20個字符 須包含大寫字母、小寫字母及數字',
    promotionalinformation: '我同意接收推廣資訊'
  },
  DeliveryAddress: {
    AddAddress: '新增地址',
    ChooseDeliveryAddress: '選擇送貨地址',
    EditBtn: '编辑',
    DeleteBtn: '删除',
    AddDeliveryAddress: '添加新的收貨地址',
    PostalCode: '郵政編碼',
    Area: '地區',
    Province: '省份',
    District: '區份',
    Address: '地址',
    DefaultAddress: '設為默認送貨地址?',
    SaveBtn: '保存',
    ResetBtn: '重置',
    CloseBtn: '关闭',
    YesBtn: '是',
    NoBtn: '否',
    AddFirstName: '名字',
    AddLastName: '姓氏',
    UserContactNumber: '聯繫電話',
    Mobile: '手提電話',
    Addressprovince: '請選擇省份',
    AddressArea: '請選擇地區',
    Detail: '街道/屋苑/樓層/室',
    Allsave: '所有保存'
  },
  Action: {
    ViewMore: '查看更多',
    SignUp: '立即報名',
    ReturnHomePage: '返回首頁',
    OrderDetails: '訂單詳情',
    Save: '保存',
    Checkout: '確認訂單',
    Confirm: '確認'
  },
  MyCoupon: {
    MyCoupon: '我的優惠券',
    Coupon: '優惠券',
    PeriodOfValidity: '有效期',
    NoCoupon: '暫無優惠券',
    CouponStatus: '優惠券狀態',
    SelectCouponStatus: '請選擇優惠券狀態',
    TotalNum: '共有',
    Piece: '張',
    NotUse: '已用',
    DoNotUse: '不可用',
    Used: '可用',
    Among: '其中',

    ALL: '所有'
  },
  MyFavorite: {
    MyFavorite: '我的最愛',
    LearnMore: '瞭解更多',
    RemoveProduct: '移除產品',
    RemoveSuccess: '移除成功',
    noFavorite: '快來拿我的錢，買我的寶貝吧!!'
  },
  Notification: {
    MyMessages: '我的信箱',
    MsgList: '消息列表',
    TotalRecQtyWithSymbol: '總記錄數 ：',
    UnreadWithSymbol: '未读記錄數 ：',
    MsgUnreadOnly: '只看未讀消息',
    MsgMarkAsRead: '標記為已讀',
    CheckAllOrNot: '全選/反選',
    MsgSender: '發信人',
    MsgSubject: '主題',
    MsgSenderDate: '發信時間',
    MsgReadStatus: '狀態',
    Details: '瞭解詳情',
    More: '更多',
    Back: '返回',
    MsgContent: '内容',
    Send: '發送',
    UnRead: '未讀',
    Aread: '已讀'
  },
  Forgetpassword: {
    ForgetPassword: '忘記密碼',
    ResetPwdTips: '請輸入您用於注冊的電子郵件，然後點擊‘下一步’繼續',
    LoginNameEmail: '用戶名/電郵',
    NextStep: '下一步',
    ResetPwdSuccess: '重置密碼已發送到您的電郵，請查看。',
    NotEmail: '收不到重設密碼電郵',
    ToReEnter: '按此重新填寫',
    EnterEmail: '請輸入電郵地址',
    CorrectEmail: '請輸入正確的電郵地址',
    ResetPwd: '重設密碼'
  },
  MemberInfo: {
    MemberInfoTitle: '用戶信息',
    UserAccount: '用戶賬號',
    ModifyPassword: '修改密碼',
    EnterUserName: '請輸入名字',
    EnterUserLastName: '請輸入姓氏',
    EnterUserPhone: '請輸入聯係電話',
    EnterUserBrithDate: '請選擇出生日期',
    EnterOldPwd: '請輸入密碼',
    EnterNewPwd: '請輸入新密碼',
    EnterComfirmPwd: '請輸入確認密碼',
    EnterNumber: '請輸入數字',
    PwdNotCorrect: '兩次密碼不一致',
    DDMM: '日/月'
  },
  Order: {
    Paid: '已支付',
    Fail: '支付失敗',
    OrderTitle: '我的訂單',
    OrderDetail: '訂單詳情',
    OrderPay: '立即支付',
    OrderCancel: '訂單取消',
    OrderComplete: '訂單完成',
    OrderClose: '訂單關閉',
    CartSubtotal: '小計',
    DeliveryCharge: '運輸費用',
    TotalPrice: '共',
    OrderStatus: '訂單狀態',
    OrderDateTime: '訂單日期&時間 ',
    OrderNumber: '訂單編號',
    OrderSearch: '訂單搜索',
    EnterProductName: '請輸入產品名稱',
    Items: '項',
    OrderComment: '評價',
    PromotionCode: '促銷碼',
    RecommendationCode: '會員推薦碼',
    CommodityPrice: '共',
    Full: '滿',
    Minus: '減',
    Hit: '減',
    Precent: '%',
    Discount: '優惠價格',
    Cancel: '取消'
  },
  Concessions: {
    Cannot: '优惠券与优惠码不可不可同时使用'
  },
  Payment: {
    OrderDetails: '訂單詳情',
    ReturnHomePage: '返回首頁',
    PayStatus: '支付狀態',
    PaymentFailTips: '本次支付失敗，請檢查您的支付戶口后重新嘗試。',
    PaymentSuccessTips: '本次支付成功'
  },
  CheckOut: {
    Ordersummary: '訂單摘要',
    Input: '請輸入',
    expiryDate: '過期日期',
    awsl: 'awsl',
    expressError: '快遞異常！請選擇其他快遞。',
    pay: '支付',
    CheckOutTitle: '確認訂單',
    Merchant: '商家',
    Product: '產品',
    Qty: '數量',
    Price: '價錢',
    SplitDelivery: '拆單',
    ShippingMethod: '送貨方式',
    PleaseSelect: '請選擇',
    Selected: '已選擇',
    Name: '名字',
    Phone: '電話',
    Address: '地址',
    Pickup: '自取',
    Change: '新增或更改地址',
    DeliveryCharge: '運輸費用',
    SubTotal: '商品小計',
    PayBy: '支付方式',
    Couponcode: '優惠碼',
    confirm: '確認',
    Total: '共',
    BacktoShoppingCart: '返回購物車',
    Last: '上一頁',
    Checkout: '確認訂單',
    Next: '下一頁',
    Delivery: '配送方式與運費',
    SelectDeliveryAddress: '選擇送貨地址',
    LastName: '姓氏',
    Edit: '更改地址',
    Delete: '刪除地址',
    Select: '選取',
    NewAddress: '添加新的收貨地址',
    PickUp: '自取',
    FirstName: '名字',
    ZipCode: '郵政編碼',
    Mobile: '手機',
    Country: '地區',
    Province: '地區/省份',
    City: '區份',
    Close: '關閉',
    PickupDate: '自取日期',
    PickupTime: '自取時間',
    Morning: '早上',
    Afternoon: '下午',
    PickAddressError: '自提信息必須完整',
    AddressLock: '地址已被選中不可刪除',
    CompanyName: '門店',
    CompanyPhone: '門店電話',
    CompanyAddress: '門店地址',
    PickupAddress: '自取地址',
    Save: '保存',
    PleaseChoose: '請選擇',
    PleaseSelectdate: '請選擇日期',
    PleaseSelecttime: '請選擇時間',
    ConfirmationOfOrder: '確認訂單',
    Concessions: '優惠信息',
    PaymentMethod: '支付方式',
    Complete: '完成',
    OrderPreview: '訂單預覽',
    CandP: '優惠券與促銷碼不能同時使用',
    promotionCodeError: '請輸入正確的促銷碼',
    promotionCodetips1: '请先删除现有的促销码',
    inputPromotionCode: '請輸入促銷碼',
    Use: '使用',
    As: '作爲',
    PaymentMethodError: '支付方式尚未选择！',
    confirmAddress: '請先確認送貨地址',
    OrderSent: '訂單已發出',
    PaymentProcess: '請繼續完成付款程序',
    CountryName: '國家名稱',
    ProvinceName: '省份名稱',
    CityName: '城市名稱',
    SFPointType: '自提點類型'
  },
  Address: {
    Country: '請選擇國家',
    Province: '請選擇省份',
    Address: '請填寫詳細地址'
  },
  MemberPoints: {
    MemberPoints: '會員積分',
    PointsDetail: '積分明細',
    PointsIncome: '積分收入',
    PointsSpending: '積分支出',
    OrderTime: '訂單時間',
    OrderNo: '訂單編號',
    Income: '收入',
    Spending: '支出',
    IncomeSpending: '收入/支出',
    DetailedDescription: '詳細説明',
    TotalPoints: '總積分',
    Hello: '您好',
    Equals: '等於',
    Integral: '積分',
    PointsTips: '積分使用常見問題',
    PointsType: '積分類別',
    Points: '積分',
    PointsMode: 'PointsMode'
  },
  Cms: {
    AboutUS: '關於我們',
    PlsChoose: '請點擊選擇您想要查看的內容：'
  },
  promotion: {
    enter: '點擊進入',
    main: '天才兒童最佳之選',
    monterssori: {
      title1: '蒙特梭利教學法',
      title2: '培養幼兒自覺主動的學習和探索精神',
      banner1: '做自己的老師',
      banner2: '讓兒童自發性地主動學習',
      banner3: '自己構建完善的人格'
    }
  },
  RegNPay: {
    BackText: '返回',
    UploadButtonText: '上傳文件(jpg, png, pdf)',
    UploadingText: '正在上傳',
    ConfirmText: '確定',
    UploadSuccessfulText: '上傳成功',
    UploadFailText: '上傳失敗',
    NoFileText: '沒有選中文件',
    UploadLengthText: '最多只能上載{0}檔案',
    UploadSizeText: '最多只能上傳{0}M',
    PleaseSelect: '請選擇',
    SubRecord: '提交紀錄',
    ReferenceNum: '參考編號',
    SubmissionDate: '提交日期',
    Print: '列印',
    PlsPayWay: '請選擇付款方式',
    PreviewTitleText: '請再次確認頁面',
    FormatErrorText: '請輸入正確電郵地址',
    RequiredText: '請檢查你的答案'
  },
  home: {
    News: '新聞',
    More: '更多',
    Category: '產品目錄',
    Weaccept: '我們接受',
    Order: '訂購',
    TelSearch: '電話查詢',
    AllProduct: '全部產品',
    ResetProduct: '重置',
    Address: '地址',
    ViewDetail: '查看詳情',
    Screening: '篩選',
    Resetall: '重置所有篩選條件',
    PriceHL: '價格從高至低',
    PriceLH: '價格從低至高',
    Thatsall: '以上是全部內容'
  }
};
