export default {
  lang: '繁',
  test: '测试',
  AddToCart: '加入購物車',
  changeLangSuccess: '語言切換成功！',
  changeCurSuccess: '貨幣切換成功！',
  Message: {
    Logout: '您尚未登陸或已登錄超時，請重新登陸',
    Message: '消息',
    Confirm: '確認',
    Cancel: '關閉',
    SucceedInOperating: '操作成功'
  },
  Account: {
    MemberInformation: '用戶信息',
    MyMessages: '我的信箱',
    MyFavorite: '我的最愛',
    MyOrder: '我的訂單',
    DeliveryAddress: '送貨地址',
    Logout: '登出',
    Welcome: '歡迎!',
    SISA: '選擇成功'
  },
  Action: {
    ViewMore: '查看更多',
    SignUp: '立即报名',
    ReturnHomePage: '返回首页',
    OrderDetails: '订单详情'
  },
  product: {
    ProductCode: '产品代码',
    buy: '立即购买',
    addToCart: '加入購物車',
    favorite: '加入我的清单',
    priceTitle: '价格',
    countTitle: '数量',
    youWouldLike: '你可能喜欢',
    moreTips: '点击加载更多',
    none: '没有了',
    noneInCat: '当前目录无产品，请选择其他目录',
    notifyMe: '到货通知',
    purchasing: '采购中',
    comments: { title: '商品评论' },
    detail: '产品详情',
    attr: { 0: '尺寸', 1: '颜色' },
    SaleOut: '售罄',
    offSale: '已下架',
    Recommend: '會員推薦',
    Remarks: '備注',
    EnterRemaks: '請填寫備注',
    up: '上一個',
    down: '下一個',
    logouted: '您已退出登陆！',
    loginow: '现在重新登入？',
    successInAdding: '添加成功',
    successInRemoving: '取消成功'
  },
  pyramidSelling: {
    ps: '传销'
  },
  Shoppingcart: {
    ShoppingcartTitle: '购物车',
    Merchant: '商家',
    Product: '产品',
    Quantity: '数量',
    Price: '价格',
    Total: '共',
    CheckoutList: '确认订单',
    None: '購物車裏空空如也，現在去購買？'
  },
  Login: {
    doLogin: '登入',
    LoginTitle: '會員登入',
    NewUser: '新用户',
    RegNow: '现在注册',
    ForgetPwd: '忘記密碼?',
    RememberMe: '記住我',
    FaceBookUse: '使用facebook賬戶登錄',
    UserName: '登錄用戶名/郵箱地址',
    EnterEmail: '請輸入郵箱地址',
    CorrectEmail: '請輸入正確郵箱地址',
    LoginPwd: '請輸入密碼',
    LoginoldPwd: '密碼不正確',
    Loginerror: '賬號或密碼不正確'
  },
  Register: {
    RegisterTitle: '註冊會員',
    UserEmail: '郵箱',
    UserRegPassword: '密碼',
    UserNewPassword: '新密碼',
    UserRegTips: '最少6個由字母或數字組成的字符',
    UserConfirmPassword: '確認密碼',
    UserFirstName: '名字',
    UserLastName: '姓氏',
    UserContactNumber: '聯繫電話',
    UserBirthDate: '出生日期',
    UserGender: '性別',
    UserMale: '男',
    UserFemale: '女',
    UserLanguage: '語言',
    UserLanguageE: '英文',
    UserLanguageT: '繁体',
    UserLanguageS: '简体',
    RegisterBtn: '注册',
    RegisterAgree: '我同意購買條款和使用條款',
    RegisterEmail: '郵箱不能為空',
    RegisterEmail01: '請輸入正式的郵箱格式',
    RegisterPassword: '請輸入密碼',
    RegisterNewPassword: '請再次輸入密碼',
    RegisterNewPassword01: '兩次輸入密碼不一致',
    RegisterFirstName: '請輸入您的姓名',
    RegisterLastName: '称請輸入您的名稱',
    RegisterAgree_check: '請勾選',
    Registerdigital: '長度由8至20 須包含大寫字母、小寫字母及數字'
  },
  DeliveryAddress: {
    ChooseDeliveryAddress: '選擇送貨地址',
    EditBtn: '编辑',
    DeleteBtn: '删除',
    AddDeliveryAddress: '添加新的收貨地址',
    PostalCode: '邮政编码',
    Area: '地區',
    Province: '省份',
    District: '區份',
    Address: '地址',
    DefaultAddress: '設為默認送貨地址?',
    SaveBtn: '保存',
    ResetBtn: '重置',
    CloseBtn: '关闭',
    YesBtn: '是',
    NoBtn: '否',
    AddFirstName: '名字',
    AddLastName: '姓氏',
    UserContactNumber: '聯繫電話',
    Mobile: '手提電話',
    Addressprovince: '請選擇省份',
    AddressArea: '請選擇地區'
  },
  MyCoupon: {
    MyCoupon: '我的优惠券',
    Coupon: '优惠券',
    PeriodOfValidity: '有效期',
    NoCoupon: '暂无优惠券',
    CouponStatus: '优惠券状态',
    SelectCouponStatus: '请选择优惠券状态',
    TotalNum: '共有',
    Piece: '张',
    NotUse: '可用',
    Used: '已用',
    Among: '其中'
  },
  MyFavorite: {
    MyFavorite: '我的最爱',
    LearnMore: '了解更多',
    RemoveProduct: '移除产品',
    RemoveSuccess: '移除成功'
  },
  Notification: {
    MyMessages: '我的信箱',
    MsgList: '消息列表',
    TotalRecQtyWithSymbol: '總記錄數 ：',
    UnreadWithSymbol: '未读記錄數 ：',
    MsgUnreadOnly: '只看未讀消息',
    MsgMarkAsRead: '標記為已讀',
    CheckAllOrNot: '全選/反選',
    MsgSender: '發信人',
    MsgSubject: '主題',
    MsgSenderDate: '發信時間',
    MsgReadStatus: '狀態',
    Details: '了解详情',
    More: '更多',
    Back: '返回',
    MsgContent: '内容',
    Send: '发送'
  },
  Forgetpassword: {
    ForgetPassword: '忘记密码',
    ResetPwdTips: '請進入你綁定的郵箱，點解下一步按鈕進行重置操作',
    LoginNameEmail: '用户名/邮箱',
    NextStep: '下一步',
    ResetPwdSuccess: '重置密碼已發送到您的郵箱，請查看。',
    NotEmail: '收不到重設密碼電郵',
    ToReEnter: '按此重新填寫',
    EnterEmail: '请输入邮箱地址',
    CorrectEmail: '请输入正确邮箱地址'
  },
  MemberInfo: {
    MemberInfoTitle: '用户信息',
    UserAccount: '用户账号',
    ModifyPassword: '修改密码',
    EnterUserName: '请输入名字',
    EnterUserLastName: '请输入姓氏',
    EnterUserPhone: '请输入联系电话',
    EnterUserBrithDate: '请选择出生日期',
    EnterOldPwd: '请输入密码',
    EnterNewPwd: '请输入新密码',
    EnterComfirmPwd: '请输入确认密码',
    EnterNumber: '请输入数字',
    PwdNotCorrect: '两次密码不一致'
  },
  Order: {
    OrderTitle: '我的订单',
    OrderDetail: '订单详情',
    OrderPay: '立即支付',
    OrderCancel: '订单取消',
    OrderComplete: '订单完成',
    OrderClose: '订单关闭',
    CartSubtotal: '小计',
    DeliveryCharge: '运输费用',
    TotalPrice: '总价',
    OrderStatus: '订单状态',
    OrderDateTime: '订单日期&时间 ',
    OrderNumber: '订单编号',
    OrderSearch: '订单搜索',
    EnterProductName: '请输入产品名称',
    Items: '项',
    OrderComment: '评价',
    PromotionCode: '促銷碼',
    RecommendationCode: '會員推薦碼',
    CommodityPrice: '商品總價'
  },
  Payment: {
    ReturnHomePage: '返回首页',
    PayStatus: '支付状态',
    PayFailTips: '本次支付失败，请检查您的支付户口后重新尝试。',
    PaysuccessTips: '本次支付成功。'
  },
  CheckOut: {
    awsl: 'awsl',
    expressError: '快递异常！请选择其他快递。',
    pay: '支付',
    CheckOutTitle: '確認訂單',
    Merchant: '商家',
    Product: '產品',
    Qty: '數量',
    Price: '價錢',
    SplitDelivery: '拆單',
    ShippingMethod: '送貨方式',
    PleaseSelect: '請選擇',
    Name: '名字',
    Phone: '電話',
    Address: '地址',
    Pickup: '自取',
    Change: '新增或更改地址',
    DeliveryCharge: '運輸費用',
    SubTotal: '商品小計',
    PayBy: '支付方式',
    Couponcode: '優惠碼',
    confirm: '确认',
    Total: '共',
    BacktoShoppingCart: '返回購物車',
    Last: '上一頁',
    Checkout: '確認訂單',
    Next: '下一頁',
    Delivery: '配送方式與運費',
    SelectDeliveryAddress: '選擇送貨地址',
    LastName: '姓氏',
    Edit: '編輯',
    Delete: '刪除',
    Select: '選擇',
    NewAddress: '添加新的收貨地址',
    PickUp: '自取',
    FirstName: '名字',
    ZipCode: '郵政編碼',
    Mobile: '手機',
    Country: '地區',
    Province: '地區/省份',
    City: '區份',
    Close: '關閉',
    PickupDate: '自取日期',
    PickupTime: '自取時間',
    Morning: '早上',
    Afternoon: '下午',
    PickupAddress: '自取地址',
    Save: '保存',
    PleaseChoose: '請選擇',
    PleaseSelectdate: '請選擇日期',
    PleaseSelecttime: '請選擇時間',
    ConfirmationOfOrder: '確認訂單',
    Concessions: '優惠信息',
    PaymentMethod: '支付方式',
    Complete: '完成',
    OrderPreview: '订单预览'
  },
  Address: {
    Country: '請選擇國家',
    Province: '請選擇省份',
    Address: '請填寫詳細地址'
  },
  MemberPoints: {
    MemberPoints: '会员积分',
    PointsDetail: '积分明细',
    PointsIncome: '积分收入',
    PointsSpending: '积分支出',
    OrderTime: '订单时间',
    OrderNo: '订单编号',
    Income: '收入',
    Spending: '支出',
    IncomeSpending: '收入/支出',
    DetailedDescription: '详细说明',
    TotalPoints: '總積分',
    Hello: '您好',
    Equals: '等于',
    Integral: '积分',
    PointsTips: '积分使用常见问题',
    PointsType: '积分类别',
    Points: '积分',
    PointsMode: 'PointsMode'
  },
  Cms: {
    AboutUS: '關於我們'
  },
  promotion: {
    enter: '点击进入',
    main: '天才儿童最佳之选',
    monterssori: {
      title1: '蒙特梭利教学法',
      title2: '培养幼儿自觉主动的学习和探索精神',
      banner1: '做自己的老师',
      banner2: '让儿童自发性地主动学习 自己构建完善的人格'
    }
  },
  RegNPay: {
    BackText: '返回',
    UploadButtonText: '上传文件(jpg, png, pdf)',
    UploadingText: '正在上传',
    ConfirmText: '确定',
    UploadSuccessfulText: '上传成功',
    UploadFailText: '上传失败',
    NoFileText: '没有选中文件',
    UploadLengthText: '最多只能上载{0}文件',
    UploadSizeText: '最多只能上传{0}M',
    PleaseSelect: '请选择',
    SubRecord: '提交纪录',
    ReferenceNum: '参考编号',
    SubmissionDate: '提交日期',
    Print: '列印',
    PlsPayWay: '请选择付款方式',
    PreviewTitleText: '请再次确认页面',
    FormatErrorText: '请输入正确电邮地址',
    RequiredText: '请检查你的答案'
  }
};
