








import { Component, Vue } from 'vue-property-decorator';
import { Loading } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { FrontE } from '@/sdk/common/SysConst';
@Component
export default class InsLayer extends Vue {
  public show:boolean = false;
  created () {
  }
  get pcBuilding () {
    return FrontE.pcBuilding;
  }
  mounted () {
    if (this.$refs.layerbody) {
      Loading.service({
        target: this.$refs.layerbody as any,
        text: '',
        fullscreen: false,
        spinner: 'el-icon-loading',
        customClass: 'IconSize'
      });
    }
  }
  updated () {
    if (this.$refs.layerbody) {
      Loading.service({
        target: this.$refs.layerbody as any,
        text: '',
        fullscreen: false,
        spinner: 'el-icon-loading',
        customClass: 'IconSize'
      });
    }
  }
  public hidden () {
    setTimeout(() => {
      this.show = false;
      document.body.style.overflowY = 'auto';
    }, 500);
    // this.show = false;
    // document.body.style.overflow = 'hidden';
  }
  public showL () {
    // this.show = true;
    // setTimeout(() => { window.scrollTo(0, 0); }, 50);
    setTimeout(() => {
      this.show = true;
      window.scrollTo(0, 0);
    }, 50);
    window.scrollTo(0, 0); document.body.style.overflowY = 'hidden';
  }
}
