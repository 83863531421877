







import { Component, Vue, Watch } from 'vue-property-decorator';
import Layer from '@/components/service/InsLayer.vue';
import { FrontE } from '@/sdk/common/SysConst';
@Component({
  components: {
    Layer
  }
})
export default class App extends Vue {
  lang:string[] = ['E', 'C', 'S'];
  mounted () {
    Vue.prototype.$ShowLayer();
    setTimeout(() => {
      Vue.prototype.$HiddenLayer();
    }, 2000);
  }
  beforeCreate () {
    if (((Vue.prototype.userAgent === 'mobile' && FrontE.mobileBuilding) || (Vue.prototype.userAgent === 'pc' && FrontE.pcBuilding)) && (this.$route.name !== 'building')) {
      this.$router.push('/building');
    }
    if (!window['MemberApi']) window['MemberApi'] = this.$Api.member;
  }
  beforeUpdate () {
    Vue.prototype.$ShowLayer();
    setTimeout(() => { Vue.prototype.$HiddenLayer(); }, 2000);
    // Api.getData(null, 10);
    if (this.$route.name !== 'home') { return; }
    if (this.$route.query.returnUrl) {
      let url = this.$route.query.returnUrl + '?';
      Object.keys(this.$route.query).forEach((e) => {
        if (e !== 'returnUrl') url += e + '=' + this.$route.query[e] + '&';
      });
      this.$router.push(url);
    } else {
      this.$nextTick(() => {
        this.$Api.promotion.GetHomeSeoData().then(result => {
          document.title = result.Data.Title;
        });
      });
    }
  }

  created () {
    // this.changLange(this.$Storage.get('locale') || this.FrontE.defaultLang);
    if (this.$Storage.get('isLogin')) {
      this.$Api.member.getProfile().then((data) => {
        this.$store.dispatch('setUser', (data.FirstName + ' ' + data.LastName).toUpperCase());
        this.$i18n.locale = this.lang[data.Language];
        this.$store.dispatch('setLang', this.lang[data.Language]);
        this.$Storage.set('locale', this.lang[data.Language]);
        this.$store.dispatch('setMemberInfo', data);
      });
    }
    let currency = this.$Storage.get('currency') ? this.$Storage.get('currency') : this.FrontE.defaultCurrency;
    this.$Api.member
      .setCurrency(currency)
      .then(result => {
        this.$store.dispatch('setCurrency', result.Currency);
        this.$Storage.set('currency', currency);
      })
      .catch(error => {
        console.log(error);
      });
  }
  private changLange (lang) {
    this.$Api.member
      .setUILanguage(lang)
      .then(result => {
        this.$i18n.locale = lang;
        this.$Storage.set('locale', lang);
      })
      .catch(error => {
        console.log(error);
      });
  }
}
